import { getSession, urlWithAdminQuery } from '@/auth'
import { IAccontInfo, IBodyUpdateUserInfo } from './types'
import { createDefaultHeaders, throwIfError } from '@/api'
import { toast } from 'react-toastify'
import { log } from '@/util'

const handleStructureResponse = (unNormalizedData) => {
  return unNormalizedData
}

/**
 * Get User Info from backend
 *
 * @returns {Promise} - Result of call to get user info
 */
export const getUserInfo = (): Promise<IAccontInfo> => {
  return getSession().then((session) => {
    let ep = `/user/${session.idToken.payload.sub || ''}`
    if (session.adminEmail !== '') ep = '/user/getByAdminEmail'
    return (
      fetch(urlWithAdminQuery(ep, session.adminEmail), {
        method: 'GET',
        headers: createDefaultHeaders({
          authToken: session.idToken.toString()
        })
      })
        .then(throwIfError)
        // Get JSON
        .then((resp) => resp.json())
        .then(handleStructureResponse)
        .catch((err) => {
          const reqId = err.headers.get('request-id')
          const newErr = new Error(`Error getting structures [requestId: ${reqId}]`)
          toast.error(newErr.message)
          log.error(newErr)
          throw newErr
        })
    )
  })
}

/**
 * Update User Info from backend
 *
 * @returns {Promise} - Result of call to Update user info
 */
export const updateUserInfo = (body: IBodyUpdateUserInfo, idUser: string): Promise<IAccontInfo> => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/user/${idUser || ''}`, session.adminEmail), {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      })
    })
      .then(throwIfError)
      // Get JSON
      .then((resp) => resp.json())
      .then(handleStructureResponse)
      .catch((err) => {
        const reqId = err.headers.get('request-id')
        const newErr = new Error(`Error getting structures [requestId: ${reqId}]`)
        toast.error(newErr.message)
        log.error(newErr)
        throw newErr
      })
  )
}
