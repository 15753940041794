import { createDefaultHeaders } from '@/api'
import { getSession, urlWithAdminQuery } from '@/auth'
import { log } from '@/util'
import { toast } from 'react-toastify'

export const putSubscribers = (body) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/subscriber`, session.adminEmail), {
      method: 'PUT',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify(body)
    }).catch((err) => {
      const newErr = new Error(`Error sending seport [Error: ${err.body}]`)
      toast.error(newErr.message)
      log.error(newErr)
    })
  )
}

export const editSubscriber = (body) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/subscriber/${body.id}`, session.adminEmail), {
      method: 'PUT',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify(body)
    }).catch((err) => {
      const newErr = new Error(`Error sending seport [Error: ${err.body}]`)
      toast.error(newErr.message)
      log.error(newErr)
    })
  )
}

export const deleteSubscribers = (body) => {
  return getSession().then((session) =>
    fetch(urlWithAdminQuery(`/subscriber/batchDelete`, session.adminEmail), {
      method: 'POST',
      headers: createDefaultHeaders({
        authToken: session.idToken.toString()
      }),
      body: JSON.stringify(body)
    }).catch((err) => {
      const newErr = new Error(`Error sending seport [Error: ${err.body}]`)
      toast.error(newErr.message)
      log.error(newErr)
    })
  )
}
