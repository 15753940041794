import * as React from 'react'
import { Container, TextField, Stack, Button } from '@mui/material'
import { useFormik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { updatePassword, getCurrentUser } from 'aws-amplify/auth';
import { toast } from 'react-toastify'

interface FormValues {
  oldPassword: string
  newPassword: string
  newConfirmPassword: string
}

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old Password is required'),
  newPassword: Yup.string().required('New Password is required'),
  newConfirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
})

const ResetPasswordPage = () => {
  const handleSubmit = (values: FormValues, { resetForm }: FormikHelpers<FormValues>) => {
    getCurrentUser()
      .then((user) => {
        return updatePassword( {oldPassword: values.oldPassword, newPassword: values.newPassword})
      })
      .catch((err) => toast.error(`Error: ${err.message}`))
      .finally(() => resetForm())
  }
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newConfirmPassword: ''
    },
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false
  })

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <h4 style={{ textAlign: 'center' }}>Reset Password</h4>
        <Stack alignItems="center">
          <TextField
            margin="dense"
            name="oldPassword"
            type="password"
            label="Old Password"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            variant="standard"
            sx={{ width: 240 }}
            error={formik.dirty && !!formik.errors.oldPassword}
            helperText={formik.errors.oldPassword}
          />
          <TextField
            margin="dense"
            name="newPassword"
            type="password"
            label="New Password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            variant="standard"
            sx={{ width: 240 }}
            error={formik.dirty && !!formik.errors.newPassword}
            helperText={formik.errors.newPassword}
          />
          <TextField
            margin="dense"
            name="newConfirmPassword"
            type="password"
            label="Confirm New Password"
            value={formik.values.newConfirmPassword}
            onChange={formik.handleChange}
            variant="standard"
            sx={{ width: 240 }}
            error={formik.dirty && !!formik.errors.newConfirmPassword}
            helperText={formik.errors.newConfirmPassword}
          />
          <Button type="submit" sx={{ mt: 2 }} variant="contained">
            Submit
          </Button>
        </Stack>
      </form>
    </Container>
  )
}

export default ResetPasswordPage
