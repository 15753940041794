import * as React from 'react'
import { Stack, Box } from '@mui/material'
import { EditableText } from '@/components/EditableText'
import { getUserInfo, updateUserInfo } from './api'
import InitialLoader from '@/components/Loader/InitialLoader'
import { IUserData } from './types'

const AccountSettings = () => {
  const [userData, setUserData] = React.useState<IUserData>()
  const [idUser, setIdUser] = React.useState<string>('')

  React.useEffect(() => {
    const getData = async () => {
      const userInfo = await getUserInfo()
      setUserData({ orgName: userInfo.user.organization_name })
      setIdUser(userInfo.user.id)
    }
    if (userData === undefined) getData()
  }, [userData])

  const handleSubmit = async (newOrgName: string) => {
    const updatedInfo = await updateUserInfo({ organization_name: newOrgName }, idUser)
    setUserData({ orgName: updatedInfo.user.organization_name })
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      {!userData ? (
        <InitialLoader />
      ) : (
        <Stack spacing={5}>
          <Box sx={{ fontSize: '36px', fontWeight: 'bold' }}>Settings</Box>
          <Stack direction="row" spacing={5} alignItems={'center'}>
            <Box sx={{ fontSize: '24px', fontWeight: 'bold' }}>Organization Name: </Box>
            {userData && userData.orgName && <EditableText value={userData.orgName} onUpdate={handleSubmit} />}
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

export default AccountSettings
