import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { SWLoadingButton } from '@/components/LoadingButton'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { format } from 'date-fns'
import moment from 'moment-timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

const useStyles = makeStyles({
  paper: {
    minHeight: 560
  }
})

const FormInputWrapper = styled(Box)({
  paddingTop: 8,
  paddingBottom: 8
})

const InputRowWrapper = styled(Box)({
  display: 'flex',
  gap: 24,
  alignItems: 'baseline'
})

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: Function
  start?: number
  end?: number
  cost?: number
  volume?: number
  unit?: 'GALLONS_1K' | 'CCF'
  type: 'ADD' | 'EDIT'
  rateMethod?: 'SIMPLESUB' | 'UTILITY' | 'CUSTOM'
  effectiveRate?: number | null
  loading: boolean
  propertyTimeZone: string
}

export interface FormValues {
  startDate: number | null
  endDate: number | null
  volume: number
  unit: string
  cost: number
  rateMethod: string
  effectiveRate: number | null
}

const METHOD_DESCRIPTION = {
  SIMPLESUB:
    'This is the recommended rate method if all water to a property is metered by SimpleSUB Water.  The cost of the utility bill will be divided based on usage, so you’ll never over or under bill tenants.  Effective Rate =  Cost / SimpleSUB Measured Total Water Usage',
  UTILITY:
    'This is the recommended rate method if not all water to a property is metered by SimpleSUB Water. An effective rate is calculated based on the water bill. Effective Rate = Cost / Utility Measured Total Water Usage.',
  CUSTOM:
    'Use this method to calculate tenant costs based on a fixed rate from the utility. Warning: Be careful never to overbill for water!'
}

enum Unit {
  GALLONS = 'GALLONS',
  LITERS = 'LITERS'
}

const billingFormValidationSchema = Yup.object().shape({
  startDate: Yup.number().required('Required!'),
  endDate: Yup.number().required('Required!')
})

const today = new Date()

export const FormDialog = ({
  start,
  end,
  volume,
  unit,
  cost,
  open,
  type,
  onClose,
  onSubmit,
  rateMethod,
  effectiveRate,
  loading = false,
  propertyTimeZone
}: Props) => {
  const browserTimeZone = dayjs.tz.guess()
  const timeZone = propertyTimeZone.trim() === '' ? browserTimeZone : propertyTimeZone
  dayjs.tz.setDefault(timeZone)
  moment.tz.setDefault(timeZone)

  const formik = useFormik<FormValues>({
    initialValues: {
      startDate: start || null,
      endDate: end || null,
      volume: volume || 0,
      unit: unit || 'GALLONS_1K',
      cost: cost || 0,
      rateMethod: rateMethod || 'SIMPLESUB',
      effectiveRate: effectiveRate || 0
    },
    onSubmit: (values) => {
      onSubmit(values)
    },
    validationSchema: billingFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false
  })
  const classes = useStyles()

  React.useEffect(() => {
    if (open) {
      formik.setValues({
        startDate: start || null,
        endDate: end || null,
        volume: volume || 0,
        unit: unit || 'GALLONS_1K',
        cost: cost || 0,
        rateMethod: rateMethod || 'SIMPLESUB',
        effectiveRate: effectiveRate || null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleDateChange = (dates) => {
    formik.setFieldValue(
      'startDate',
      dayjs.tz(dates[0], timeZone).set('hours', 0).set('seconds', 0).set('minutes', 0).set('milliseconds', 0).valueOf()
    )
    formik.setFieldValue(
      'endDate',
      dayjs
        .tz(dates[1], timeZone)
        .set('hours', 23)
        .set('seconds', 59)
        .set('minutes', 59)
        .set('milliseconds', 0)
        .valueOf()
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={'md'}
      fullWidth
      classes={{
        paper: classes.paper
      }}
    >
      <DialogTitle id="form-dialog-title">{type === 'EDIT' ? 'Edit' : 'Add'} Bill</DialogTitle>
      <DialogContent>
        <FormInputWrapper>
          <DialogContentText marginBottom={1}>{`Please ${
            type === 'EDIT' ? 'edit' : 'add'
          } bill information`}</DialogContentText>
          <RangePicker
            format={'MMMM D, YYYY'}
            maxDate={dayjs(format(today, 'yyyy-MM-dd'), dateFormat)}
            onChange={(value, dateString) => handleDateChange(dateString)}
            defaultValue={
              formik.values.startDate && formik.values.endDate
                ? [dayjs.tz(formik.values.startDate, timeZone), dayjs.tz(formik.values.endDate, timeZone)]
                : undefined
            }
          />
          {(formik.errors.startDate || formik.errors.endDate) && (
            <Typography variant="body2" sx={{ color: '#e53935' }}>
              This field is required
            </Typography>
          )}
        </FormInputWrapper>
        <FormInputWrapper>
          <DialogContentText>Water Usage (also called Consumption)</DialogContentText>
          <InputRowWrapper>
            <TextField
              margin="dense"
              name="volume"
              type="number"
              value={formik.values.volume}
              onChange={formik.handleChange}
              variant="standard"
              sx={{ width: 180 }}
            />

            <FormControl variant="standard" margin="dense" sx={{ minWidth: 180 }}>
              <Select value={formik.values.unit} name="unit" onChange={formik.handleChange}>
                <MenuItem value="GALLONS_1K">1000 Gallons</MenuItem>
                <MenuItem value="CCF">CCF (Centum Cubic Feet)</MenuItem>
              </Select>
            </FormControl>
          </InputRowWrapper>
        </FormInputWrapper>
        <FormInputWrapper>
          <DialogContentText>Cost</DialogContentText>
          <InputRowWrapper>
            <TextField
              margin="none"
              type="number"
              name="cost"
              value={formik.values.cost}
              onChange={formik.handleChange}
              variant="standard"
              sx={{ width: 180 }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>
              }}
            />
            <Typography variant="body2">
              Enter the amount of utility bill to split among the tenants. Typically the total cost.
            </Typography>
          </InputRowWrapper>
        </FormInputWrapper>
        <FormInputWrapper>
          <DialogContentText>Rate Method</DialogContentText>
          <InputRowWrapper>
            <FormControl margin="dense" variant="standard" sx={{ minWidth: 180 }}>
              <Select
                value={formik.values.rateMethod}
                variant="standard"
                name="rateMethod"
                onChange={formik.handleChange}
              >
                <MenuItem value="SIMPLESUB">SimpleSUB Effective</MenuItem>
                <MenuItem value="UTILITY">Utility Effective</MenuItem>
                <MenuItem value="CUSTOM">Custom</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2">{METHOD_DESCRIPTION[formik.values.rateMethod]}</Typography>
          </InputRowWrapper>
        </FormInputWrapper>
        {formik.values.rateMethod === 'CUSTOM' && (
          <FormInputWrapper>
            <DialogContentText>Bill Rate</DialogContentText>
            <InputRowWrapper>
              <TextField
                margin="none"
                type="number"
                name="effectiveRate"
                value={formik.values.effectiveRate}
                onChange={formik.handleChange}
                variant="standard"
                sx={{ width: 180 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                  endAdornment: <InputAdornment position="end">/ 1 {Unit[formik.values.unit]}</InputAdornment>
                }}
              />
            </InputRowWrapper>
          </FormInputWrapper>
        )}
      </DialogContent>
      <DialogActions>
        <SWLoadingButtonOutlined onClick={onClose}>Cancel</SWLoadingButtonOutlined>
        <SWLoadingButton onClick={() => formik.submitForm()} loading={loading}>
          {type === 'EDIT' ? 'Save' : 'Add Bill'}
        </SWLoadingButton>
      </DialogActions>
    </Dialog>
  )
}
