import { useCallback, useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { usePDF } from 'react-to-pdf'
import { convertArrayToCsvString, convertTimestampToTimeZone } from '../../util'
import { formatNumber, sortBy } from '../../util'
import { StructureBilling } from '@/Structures/types'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { SWLoadingButton } from '@/components/LoadingButton'
import { sendTenantReport } from '@/Structures/api'
import React from 'react'
import { Address } from '@/types'

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: theme.spacing(70)
  },
  dialogActionsRoot: {
    '@media print': {
      display: 'none'
    }
  }
}))

type Props = {
  bill: StructureBilling
  onClose: () => void
  open: boolean
  structureName: string
  autoDownload: 'csv' | 'pdf' | null
  needReportButton: boolean
  structureAddress: Address
  timeZone: string
}

export const Breakdown = ({
  bill,
  structureName,
  open,
  onClose,
  autoDownload,
  needReportButton,
  structureAddress,
  timeZone
}: Props): JSX.Element => {
  const classes = useStyles()
  const formattedStartDate = convertTimestampToTimeZone(bill.start_time, timeZone)
  const formattedEndDate = convertTimestampToTimeZone(bill.end_time, timeZone)
  const title = `${structureName} (${formattedStartDate} - ${formattedEndDate})`
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isConfirmReport, setIsConfirmReport] = React.useState<boolean>(false)

  const handleCsvDownload = useCallback(() => {
    convertArrayToCsvString(
      bill.units.map((row) => ({
        'Unit Name': row.name,
        'Structure Name': bill.structure_name,
        Volume: formatNumber(row.unit_total_volume, 3),
        'Volume Units': bill.volume_units,
        'Effective Rate': formatNumber(bill.effective_rate, 2),
        Cost: formatNumber(row.unit_cost_meter, 2),
        'Cost Units': bill.cost_units
      }))
    ).then((csv) => saveAs(new Blob([csv], { type: 'text/plain;charset=utf-8' }), `${title.replace(/ /g, '_')}.csv`))
  }, [bill, title])

  useEffect(() => {
    const handleAction = async () => {
      await handleCsvDownload()
      onClose()
    }
    if (open && autoDownload) {
      if (autoDownload === 'csv') {
        handleAction()
      }
    }
  }, [open, autoDownload, handleCsvDownload, onClose])

  const handleSendReport = async () => {
    setLoading(true)
    await sendTenantReport(bill.billing_id)
    setLoading(false)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      maxWidth={isConfirmReport ? 'sm' : 'md'}
      fullWidth
      classes={{
        paper: classes.paper
      }}
    >
      {!isConfirmReport && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        {!isConfirmReport && <RenderTable bill={bill} autoDownload={autoDownload} title={title} onClose={onClose} />}
        {isConfirmReport && (
          <RenderConfirmReport
            propertyName={bill.structure_name}
            address={`${structureAddress.street}, ${structureAddress.state}, ${structureAddress.city}, ${structureAddress.zip}`}
            dateRange={`${formattedStartDate} - ${formattedEndDate}`}
          />
        )}
      </DialogContent>
      {!needReportButton && !isConfirmReport && (
        <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
          <SWLoadingButtonOutlined onClick={handleCsvDownload}>Download CSV</SWLoadingButtonOutlined>
          <SWLoadingButton onClick={async () => window.print}>Download PDF</SWLoadingButton>
        </DialogActions>
      )}
      {needReportButton && !isConfirmReport && (
        <DialogActions classes={{ root: classes.dialogActionsRoot }} sx={{ justifyContent: 'flex-end !important' }}>
          <SWLoadingButton onClick={() => setIsConfirmReport(true)}>Send</SWLoadingButton>
        </DialogActions>
      )}
      {isConfirmReport && (
        <DialogActions
          classes={{ root: classes.dialogActionsRoot }}
          sx={{ justifyContent: 'space-between !important' }}
        >
          <SWLoadingButtonOutlined onClick={() => setIsConfirmReport(false)}>Back</SWLoadingButtonOutlined>
          <SWLoadingButton onClick={handleSendReport}>
            {loading ? (
              <>
                Loading...
                <CircularProgress size={20} color="inherit" sx={{ marginLeft: '10px' }} />
              </>
            ) : (
              'Confirm Send'
            )}
          </SWLoadingButton>
        </DialogActions>
      )}
    </Dialog>
  )
}

interface IRenderConfirmReport {
  propertyName: string
  address: string
  dateRange: string
}

const RenderConfirmReport = (props: IRenderConfirmReport) => {
  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h3" textAlign={'center'} fontWeight={'bold'}>
          Send usage reports
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6" textAlign={'center'}>
          Water usage reports will be sent to the tenants for each unit. <br /> Please check the information below and
          confirm send.
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Stack>
          <Stack direction={'row'} spacing={1}>
            <Box fontWeight={'bold'}>Property: </Box>
            <Box>{props.propertyName}</Box>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <Box fontWeight={'bold'}>Address: </Box>
            <Box>{props.address}</Box>
          </Stack>
          <Stack direction={'row'} spacing={1}>
            <Box fontWeight={'bold'}>Date range: </Box>
            <Box>{props.dateRange}</Box>
          </Stack>
        </Stack>
      </Box>
    </Stack>
  )
}

const RenderTable = ({
  bill,
  autoDownload,
  title,
  onClose
}: {
  bill: StructureBilling
  autoDownload: 'csv' | 'pdf' | null
  title: string
  onClose: Function
}) => {
  const { toPDF, targetRef } = usePDF({ filename: `${title.replace(/ /g, '_')}_report.pdf` })
  const [alredyExported, setAlredyExported] = useState<boolean>(false)
  useEffect(() => {
    if (autoDownload && !alredyExported) {
      if (autoDownload === 'pdf') {
        setAlredyExported(true)
        toPDF()
        onClose()
      }
    }
  }, [autoDownload, toPDF, alredyExported, onClose])

  return (
    <div ref={targetRef}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Unit Name</TableCell>
              <TableCell>Property Name</TableCell>
              <TableCell>Volume</TableCell>
              <TableCell>Volume Unit</TableCell>
              <TableCell>Effective Rate</TableCell>
              <TableCell align="right">Cost</TableCell>
              <TableCell align="right">Cost Units</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bill.units.sort(sortBy('name')).map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {bill.structure_name}
                </TableCell>
                <TableCell align="right">{formatNumber(row.unit_total_volume, 3)}</TableCell>
                <TableCell component="th" scope="row">
                  {bill.volume_units}
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatNumber(bill.effective_rate, 2)}
                </TableCell>
                <TableCell align="right">{`$${formatNumber(row.unit_cost_meter, 2)}`}</TableCell>
                <TableCell component="th" scope="row">
                  {bill.cost_units}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell component="th" scope="row">
                Total
              </TableCell>
              <TableCell component="th" scope="row" />
              <TableCell align="right">
                {formatNumber(bill.units.reduce((acc, cur) => acc + cur.unit_total_volume, 0))}
              </TableCell>
              <TableCell component="th" scope="row" />
              <TableCell component="th" scope="row" />
              <TableCell align="right">{`$${formatNumber(
                bill.units.reduce((acc, cur) => acc + cur.unit_cost_meter, 0)
              )}`}</TableCell>
              <TableCell component="th" scope="row" />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
