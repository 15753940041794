import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import 'react-day-picker/dist/style.css'
import { useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { getSuscribers } from '../api'
import { SuscribersTable } from './table'
import { useStructure } from '../useStructures'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  selectedDays: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

export const SubscribersView = () => {
  const classes = useStyles()
  const [subscribersData, setSubscribersData] = React.useState()
  const [needLoadData, setNeedLoadData] = React.useState(true)

  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  // Data fetching ////////////////////
  const { structure } = useStructure(structureId)

  React.useEffect(() => {
    const getData = async () => {
      const data = await getSuscribers(structureId)
      setSubscribersData(data.subscribers)
      setNeedLoadData(false)
    }
    if (needLoadData) getData()
  }, [subscribersData, structureId, needLoadData])

  React.useEffect(() => {
    setNeedLoadData(true)
  }, [structureId])

  return (
    <ErrorBoundary>
      <div data-component-id={'STRUCTURE-VIEW'} className={classes.wrapper}>
        {subscribersData && structure && (
          <div className={classes.container}>
            <div className={classes.header}>
              <div style={{ flexGrow: 1 }}>
                <SuscribersTable
                  subscribers={subscribersData}
                  units={structure.units}
                  setNeedLoadData={setNeedLoadData}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  )
}
