import * as React from 'react'
import { Outlet, Link, useNavigate } from 'react-router-dom'
import { IconButton, Menu, MenuItem, Toolbar, Tooltip } from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import MenuIcon from '@mui/icons-material/Menu'
import { signOut } from 'aws-amplify/auth'
import { Drawer } from './Drawer'
import { ErrorBoundary } from '../ErrorBoundary'
import { drawerWidth } from './util'
import makeStyles from '@mui/styles/makeStyles'
import { grey as gray } from '@mui/material/colors'
import { MiniAppBar } from './components'
import { useAdminAccess } from '../../useAdminAccess'

const greenColor = '#4CEFDA'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%'
  },
  appBar: {
    // width: `calc(100% - ${drawerWidth})`,
    // marginLeft: drawerWidth
    backgroundColor: 'white'
  },
  color: {
    color: gray[50]
  },
  content: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    width: `calc(100% - ${drawerWidth})`,
    marginLeft: drawerWidth,
    height: 'calc(100vh - 64px)',
    marginTop: 64,
    padding: 24,
    overflowY: 'scroll'
  },
  fullContent: {
    backgroundColor: 'white',
    boxSizing: 'border-box',
    height: 'calc(100vh - 64px)',
    marginTop: 64,
    padding: 24,
    overflowY: 'scroll',
    width: `calc(100% - ${'64px'})`,
    marginLeft: '64px'
  },
  headerText: {
    color: '#353838',
    fontWeight: 'bold',
    fontFamily: 'BC Alphapipe L'
  },
  headerLogo: {
    height: 55
  }
})

interface AppShellProps {
  hideDrawer?: boolean
}

export const AppShell = ({ hideDrawer = false }: AppShellProps): JSX.Element => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)
  const [openDrawer, setOpenDrawer] = React.useState(true)
  const navigate = useNavigate()
  const { deleteAdminInfo } = useAdminAccess()

  /**
   * Handle menu open
   *
   * @param  {MouseEvent} event - User event that triggered the open
   */
  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  /**
   * Handle menu close
   */
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  /**
   * Logout
   */
  const logout = async () => {
    handleClose()
    try {
      deleteAdminInfo()
      await signOut()
    } catch (e) {
      console.error(e)
    } finally {
      window.location.assign('/')
    }
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
  }

  return (
    <ErrorBoundary>
      <div data-component-id={'APP-SHELL'} className={classes.root}>
        <MiniAppBar position="fixed" className={classes.appBar} open={!hideDrawer && openDrawer}>
          <Toolbar>
            {!hideDrawer && (
              <IconButton
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  ...(openDrawer && { display: 'none' })
                }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Link to="/">
              <img src="/logo-light.png" alt="SimpleSub Water" className={classes.headerLogo} />
            </Link>
            <div style={{ marginLeft: 'auto' }}>
              <Tooltip title="Contact Support" placement="bottom" arrow>
                <a href="https://simplesubwater.zendesk.com" target="_blank" rel="noreferrer">
                  <IconButton
                    aria-label="contact support"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                    size="large"
                  >
                    <ContactSupportIcon style={{ color: greenColor }} />
                  </IconButton>
                </a>
              </Tooltip>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                size="large"
              >
                <AccountCircle style={{ color: greenColor }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={() => navigate('/account/settings')}>Settings</MenuItem>
                <MenuItem onClick={() => navigate('/accounts/reset-password')}>Reset Password</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </MiniAppBar>
        {!hideDrawer && <Drawer open={openDrawer} setOpen={setOpenDrawer} />}
        <div
          data-component-id={'APP-SHELL_CHILDREN-CONTAINER'}
          className={!hideDrawer && openDrawer ? classes.content : classes.fullContent}
        >
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  )
}
